import React from 'react';
import Link from 'next/link';
import { FaCalendarAlt } from 'react-icons/fa';

const NewsCard = ({ id, imageSrc, date, title }) => {
  const truncateText = (text, maxLength = 100) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <Link href={`/ittiloot/news/${id}`} passHref>
      <div style={{ cursor: 'pointer' }} className="border rounded-lg overflow-hidden shadow-md">
        <img
          src={imageSrc}
          alt={Array.isArray(title) ? title[0] : title}
          className="w-full h-48 object-cover"
        />
        <div className="p-4">
          <div className="flex items-center text-sm text-gray-500 space-x-2">
            <div className="bg-gray-200 p-2 rounded-full">
              <FaCalendarAlt style={{ color: '#293b8e', fontSize: '1.25rem' }} />
            </div>
            <span style={{
              fontSize: '11px'
            }} className="text-gray-700">{date}</span>
          </div>
          <div className="flex flex-col text-base text-black flex-grow mt-2">
            {Array.isArray(title) ? (
              title.map((line, index) => (
                <p key={index} className={index !== 0 ? "mt-1.5" : ""}>
                  {capitalizeFirstLetter(truncateText(line))}
                </p>
              ))
            ) : (
              <p>{capitalizeFirstLetter(truncateText(title))}</p>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;
