import React, { useState } from 'react';
import Image from "next/image";

const OlympiadCard = ({ imageSrc, iconSrc, title }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="flex flex-col w-full sm:w-1/2 md:w-1/4 p-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex overflow-hidden relative flex-col grow text-xl leading-6 text-center text-white uppercase aspect-[2.285] h-40 sm:h-48 md:h-56">
        <Image
          loading="lazy"
          src={imageSrc}
          height={500}
          width={500}
          alt="Category Image"
          className={`object-cover absolute inset-0 w-full h-full transition-transform duration-300 ease-in-out ${isHovered ? 'scale-110' : 'scale-100'}`}
        />
        <div
          className={`flex relative flex-col justify-center p-2 w-full h-full bg-slate-800 transition-opacity duration-300 ease-in-out ${isHovered ? 'bg-opacity-90' : 'bg-opacity-80'}`}
        >
          <div className="flex gap-5 px-5 py-4 max-md:px-5 items-center">
            <Image
              loading="lazy"
              src={iconSrc}
              height={800}
              width={400}
              alt="Category Icon"
              className={`object-contain shrink-0 w-8 sm:w-10 aspect-square transition-transform duration-300 ease-in-out ${isHovered ? 'scale-110' : ''}`}
            />
            <div className={`transition-transform duration-300 ease-in-out ${isHovered ? 'scale-110' : ''}`}>
              {title}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OlympiadCard;
