import React, { useEffect, useState } from "react";
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const CallToAction = () => {
  const [videos, setVideos] = useState([]);
  const { t } = useTranslation('common'); // Get translations from common.json
  const router = useRouter();
  const { locale } = router;

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(`https://hotamiolymp.com/wp-json/custom-navor/v1/latest-videos`);
        const data = await response.json();
        setVideos(data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, [locale]);

  // Function to extract text by language
  const extractTextByLanguage = (text, language) => {
    // Split text into language parts based on the language markers
    const parts = text.split(/(?=(tj|ru|en)\$)/).map(part => part.trim());

    // Loop through each part to find the one matching the selected language
    for (let part of parts) {
      if (part.startsWith(`${language}$`)) {
        return part.replace(`${language}$`, '').trim();
      }
    }

    return ''; // Return empty string if the language is not found
  };


  return (
    <div className="pt-5 container">
      <h4 className="text-center py-3 m-0 tracking-tight leading-loose text-blue-900">{t('videos.navor')}</h4>
      <div className="row g-5">
        {videos.length > 0 ? (
          videos.map((video, index) => (
            <div className="col-lg-6" key={index} style={{ cursor: "pointer" }}>
              <div className="rbt-callto-action callto-action-default bg-color-white rbt-radius shadow-1">
                <div className="col align-items-center">
                  <div className="video-popup-wrapper mt_lg--10 mt_md--20 mt_sm--20" style={{ position: "relative", width: "100%", height: 0, paddingTop: "56.25%" }}>
                    <video controls style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                      <source src={video.video_url} type="video/mp4" />
                      {t('videos.error')}
                    </video>
                  </div>
                  <div className="inner">
                    <h6 className="title mb--15 pt-3 text-center">
                      {extractTextByLanguage(video.title, locale)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p> {t('videos.noData')} </p>
        )}
      </div>
    </div>
  );
};

export default CallToAction;
