import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import sal from 'sal.js';
import 'sal.js/dist/sal.css';
import Slider from '../Slider/Slider';
import NewsSection from '../News/NewsSection';
import OlympiadSection from '../OlympiadCard/OlympiadSection';
import AnnouncementsComponent from '../Announcement/AnnouncementsComponent';
import CallToAction from '../Call-To-Action/CallToAction';
import ImageGallery from '../ImageSliderSponsors/ImagesGallery';
import Inforgraphic from '../Inforgraphic/Inforgraphic.js';
import { Oval } from 'react-loader-spinner'; // Importing the loading spinner
import { useTranslation } from 'next-i18next';



const MainDemo = () => {
  const [screenSize, setScreenSize] = useState(typeof window !== 'undefined' ? window.innerWidth : undefined);
  const { t } = useTranslation('common'); // Get translations from common.json

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px'
      }}>
        <Oval
          height={50}
          width={50}
          color="#004a7c"
          secondaryColor="#e2e8f0"
          strokeWidth={4}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }

  const isSmallScreen = screenSize < 600;
  const isMediumScreen = screenSize >= 600 && screenSize < 792;
  const isLargeScreen = screenSize >= 792;

  const sharedSectionStyles = {
    width: '100%',
    padding: '0 10px'
  };

  const presidentSection = (
    <div className="pb-4" style={sharedSectionStyles}>
      <h5 style={{ borderBottom: '3px solid #004a7c' }}>{t('president.title')}</h5>
      <div style={{ border: '1px solid #ccc', padding: '10px', background: '#fafafa' }}>
        <a href="http://president.tj/" target="_blank" rel="noopener noreferrer">
          <Image
            src="/images/President/president.jpg"
            alt={t('president.title')}
            sizes="100vw"
            width={800}
            height={400}
            style={{ width: '100%' }}
          />
        </a>
      </div>
      <a href="#" style={{ display: 'block', padding: '10px 15px', fontSize: "14px", background: '#004a7c', color: '#fff', textAlign: 'center', textDecoration: 'none' }}>
        {t('president.description')}
      </a>
      <a href="http://president.tj/" target="_blank" rel="noopener noreferrer" style={{ display: 'block', padding: '0.4375rem 0.875rem', color: '#004a7c', background: '#fff', border: '1px solid #ddd', textAlign: 'center', textDecoration: 'none' }}>
        {t('president.website')}
      </a>
    </div>
  );

  const announcementsSection = (
    <div style={sharedSectionStyles}>
      <AnnouncementsComponent />
    </div>
  );

  return (
    <div style={{
      boxSizing: 'border-box',
      padding: '24px',
      margin: '0 auto',
      width: '100%',
      maxWidth: '1280px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: '#fff',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: isLargeScreen ? 'row' : 'column',
        width: '100%',
      }}>
        <div style={{
          width: isLargeScreen ? '75%' : '100%',
          padding: '0 10px',
        }}>
          <Slider />
          <NewsSection />
          {isMediumScreen && (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              {presidentSection}
              {announcementsSection}
            </div>
          )}
          {isSmallScreen && presidentSection}
          {isSmallScreen && announcementsSection}
        </div>
        {isLargeScreen && (
          <div style={{ width: '25%', padding: '0 10px' }}>
            {presidentSection}
            {announcementsSection}
          </div>
        )}
      </div>
      <CallToAction />
      <div style={{ height: '5vh' }}></div>
      <Inforgraphic />
      <ImageGallery />
    </div>
  );
};

export default MainDemo;
